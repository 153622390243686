import styled from 'styled-components'

import { Dash, Flex, Icon } from 'Components/UI'
import { printGlyph } from 'Assets/Svg'

export const IconsContainer = styled(Flex).attrs({})`
  flex: 1;
  gap: 18px;

  .share-icon:hover {
    opacity: 0.7;
  }
`

export const PrintButton = styled('button').attrs({
  className: 'react-share__ShareButton',
})`
  background-color: rgb(82, 82, 91);
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`

export const PrintIcon = styled(Icon).attrs({
  glyph: printGlyph,
})`
  width: 16px;
  height: 16px;
  color: white;
  stroke: white;
  fill: white;
`

export const PrintContent = styled(Flex).attrs({
  p: 48,
})`
  flex-direction: column;
  gap: 12px;
  background-color: white;
  height: 100vh;
  overflow-wrap: break-word;
`

export const Separator = styled(Dash).attrs({
  my: '12px',
})``
