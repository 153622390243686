import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'

import { Flex } from 'Components/UI'

import { Label } from 'Components/Blocks/ServiceCard/styles'
import { From, PriceWrapper, PricingWrapper, StyledButton } from './styles'
import { PriceCross, PriceHolder, PriceLabel } from '../Content/Options/styles'

const getPrice = item => {
  return (item.price || '').replace(/\.00$/, '')
}

const getRRP = item => {
  const rrp = get(item, 'recommended_retail_price')
  const price = get(item, 'price')

  return rrp === price ? null : (rrp || '').replace(/\.00$/, '')
}

function PricePanel({ service, onShowWidget, ...rest }) {
  const { primaryColor, primaryTextColor, roundedBorder } = useBaseTheme()

  const pricing = get(service, 'minimum_price', service)

  const price = getPrice(pricing)
  const recommendedRetailPrice = getRRP(pricing)
  const discountPercent = get(pricing, 'discount_percent')
  const priceLabel = get(service, 'price_label')
  const hasOptions = get(service, 'options.length', 0) > 0
  const isFree =
    pricing.base_price === 0 && !hasOptions && !recommendedRetailPrice
  const isPriceHidden = get(service, 'is_price_hidden', false)
  const showBookNow = get(service, 'is_book_button_visible', true)
  const bookButtonLabel =
    get(service, 'book_button_label') || _('common.bookNow')

  // Hide the entire panel if there's no price to show
  // and we're not showing the book now button
  if (isPriceHidden && !showBookNow) {
    return null
  }

  return (
    <PricingWrapper color={primaryColor} mb={[0, 0, 0, 3]} {...rest}>
      {!isPriceHidden && (
        <PriceWrapper>
          {isFree ? (
            <PriceHolder>{_('service.free')}</PriceHolder>
          ) : (
            <Flex alignItems="center" flexDirection={['row', 'row', 'column']}>
              <Flex>
                <PriceHolder>
                  {hasOptions && <From />}

                  {recommendedRetailPrice && (
                    <PriceCross>{recommendedRetailPrice}</PriceCross>
                  )}

                  {price}

                  {discountPercent ? (
                    <Label
                      bgcolor="linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #E85815"
                      color="#E85815"
                      invert={0}
                    >
                      {Math.round(discountPercent)}% OFF
                    </Label>
                  ) : null}
                </PriceHolder>
              </Flex>
              {priceLabel && (
                <Flex>
                  <PriceLabel>{priceLabel}</PriceLabel>
                </Flex>
              )}
            </Flex>
          )}
        </PriceWrapper>
      )}

      {showBookNow && (
        <StyledButton
          background={primaryColor}
          color={primaryTextColor}
          roundedborder={roundedBorder}
          onClick={() => onShowWidget(false)}
        >
          {bookButtonLabel}
        </StyledButton>
      )}
    </PricingWrapper>
  )
}

PricePanel.defaultProps = {
  service: null,
}

PricePanel.propTypes = {
  service: PropTypes.object,
  onShowWidget: PropTypes.func.isRequired,
}

export default PricePanel
