import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Box, Modal } from 'Components/UI'

import { useAppContext, useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'
import Labels from 'Constants/labels'

import Telephone from 'Containers/Pages/Public/Merchant/Content/Telephone'
import Share from './Share'
import {
  Heading,
  Link,
  MessageIcon,
  ModalButton,
  ModalContent,
  ModalText,
  Row,
  Section,
  Separator,
  WebsiteIcon,
  WebsiteText,
} from './styles'

const Social = ({ service, ...rest }) => {
  const history = useHistory()

  const { config } = useAppContext()

  const {
    labels,
    primaryColor,
    primaryTextColor,
    supportEmail,
    websitePopupMessage,
  } = useBaseTheme()

  const [showWebsitePopup, setShowWebsitePopup] = useState()

  const phone = get(service, 'phone_number')
  const website = get(service, 'business.website')

  const goToWebsite = () => {
    const prefix = website.startsWith('http') ? '' : 'https://'

    const newWindow = window.open()
    newWindow.opener = null
    newWindow.location = `${prefix}${website}`

    setShowWebsitePopup(false)
  }

  const handleWebsiteClick = useCallback(() => {
    if (websitePopupMessage) {
      setShowWebsitePopup(!showWebsitePopup)
    } else {
      goToWebsite()
    }
  })

  const marketplaceUrl = `https://${get(config, 'host')}${get(
    history,
    'location.pathname',
  )}`

  return (
    <Box width="100%" {...rest}>
      <Heading>
        {get(
          labels,
          Labels.TITLE_SERVICE_CONTACT_ORGANISER,
          'Contact the organiser',
        )}
      </Heading>

      <Section>
        <Row>{service.business.name}</Row>
        {phone && (
          <Row>
            <Telephone color="#52525b" phone={phone} size={16} />
          </Row>
        )}
        {website && (
          <>
            <Row>
              <WebsiteIcon />
              <WebsiteText onClick={handleWebsiteClick}>Website</WebsiteText>
            </Row>
            <Modal isOpen={showWebsitePopup} onCallback={handleWebsiteClick}>
              <ModalContent>
                <ModalText>{websitePopupMessage}</ModalText>
                <ModalButton
                  background={primaryColor}
                  color={primaryTextColor}
                  onClick={goToWebsite}
                >
                  {_('action.ok')}
                </ModalButton>
              </ModalContent>
            </Modal>
          </>
        )}
      </Section>

      <Box className="hide-print">
        <Separator />
        <Heading>{_('service.social.missingSomething')}</Heading>
        <Section>
          <Row>
            <MessageIcon />
            <Link
              href={`mailto:${supportEmail}?subject=Listing feedback - ${marketplaceUrl}`}
            >
              {_('service.social.helpImprove')}
            </Link>
          </Row>
        </Section>
      </Box>

      <Box className="hide-print">
        <Separator />
        <Heading>{_('service.social.share')}</Heading>
        <Share />
      </Box>
    </Box>
  )
}

Social.defaultProps = {}

Social.propTypes = {
  service: PropTypes.object.isRequired,
}

export default Social
