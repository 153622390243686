import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { useReactToPrint } from 'react-to-print'
import { get } from 'lodash'

import { getService } from 'Store/Selectors/marketplace'
import { useBaseTheme } from 'Hooks'
import Labels from 'Constants/labels'

import { Box, Flex } from 'Components/UI'
import { PrintButton, PrintIcon, PrintContent, Separator } from './styles'
import { MerchantLogo } from '../../Merchant/styles'

const Print = ({ service, ...rest }) => {
  const contentRef = useRef(null)
  const { labels } = useBaseTheme()

  const reactToPrintFn = useReactToPrint({
    contentRef,
  })

  if (!service) {
    return null
  }

  const location = service.locations[0]

  return (
    <>
      <PrintButton {...rest} onClick={reactToPrintFn}>
        <PrintIcon />
      </PrintButton>
      <Box style={{ display: 'none' }}>
        <PrintContent ref={contentRef}>
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="20px">{service.name}</Box>
            <Box>
              <MerchantLogo src={service.business.logo} />
            </Box>
          </Flex>
          <Separator />
          <Box mt={1}>
            <strong>
              {get(labels, Labels.TITLE_SERVICE_ORGANISED_BY, 'Organised by')}
            </strong>
            : {service.business.name}
          </Box>
          {location?.full_address && (
            <Box>
              <strong>Address:</strong> {location.name} {location.full_address}
            </Box>
          )}
          {service.phone_number && (
            <Box>
              <strong>Phone:</strong> {service.phone_number}
            </Box>
          )}
          {service.business.website && (
            <Box>
              <strong>Website:</strong> {service.business.website}
            </Box>
          )}
        </PrintContent>
      </Box>
    </>
  )
}

Print.defaultProps = {
  service: null,
}

Print.propTypes = {
  service: PropTypes.object,
}

const selector = createStructuredSelector({
  service: getService,
})

export default connect(selector, {})(Print)
